@import 'normalize.css';
@import 'font-awesome/css/font-awesome.css';
@import '@fontsource/open-sans/latin';

s {
  text-decoration: none;
  position: relative;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    background: currentColor;
  }
  &:before {
    top: 45%;
  }
  &:after {
    top: 55%;
  }
}
